import React, {Component} from 'react';
import '../../styles/components/contacts/contact-form.scss';
import axios from 'axios';
import '../../styles/utils/general.scss';
import {validateEmail} from '../../utils/helpers';

const INITIAL_STATE = {
  email: '',
  message: '',
  to: 'info@3decide.com',
  tid: 'd-d88efab6754940efa00092f08033e655',
  error: null,
  mailSent: null,
};
const API_PATH = 'http://sendmail.monade.tech/sendmail';

class ContactForm extends Component {
  constructor (props) {
    super (props);

    this.state = {...INITIAL_STATE};
  }
  onSubmit = event => {
    event.preventDefault ();

    if (validateEmail (this.state.email)) {
      axios ({
        method: 'post',
        url: `${API_PATH}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify (this.state),
      })
        .then (result => {
          this.setState ({
            mailSent: result.data.status,
          });
        })
        .catch (error => {
          this.setState ({error: error.message}); //not being used
        });
    } else {
      this.setState ({
        mailSent: false,
      });
    }
  };

  render () {
    const {email, message} = this.state;

    const isInvalid = message === '' || email === '';

    return (
      <div className="contact-form-component-wrapper">
        <form onSubmit={this.onSubmit}>
          <label htmlFor="input" className="contact-form-label">
            Email
          </label>

          <input
            className="email-input typo-body2-white"
            name="email"
            value={email}
            onChange={event =>
              this.setState ({[event.target.name]: event.target.value})}
            type="text"
          />
          <label htmlFor="input" className="contact-form-label">
            {this.props.locale === 'en' ? 'Message' : 'Mensagem'}
          </label>
          <textarea
            className="message-input typo-body2-white"
            name="message"
            value={message}
            onChange={event =>
              this.setState ({[event.target.name]: event.target.value})}
          />

          <div className="feedback-messages typo-captions-red">
            {this.state.mailSent &&
              <div className="success-feedback typo-captions-green">
                {this.props.locale === 'en'
                  ? 'Thank you for contacting us'
                  : 'Obrigado por nos contactar'}
              </div>}
            {this.state.mailSent !== null &&
              !this.state.mailSent &&
              (this.props.locale === 'en'
                ? <div className="error-feedback">
                    Invalid email. Please try again.
                  </div>
                : <div className="error-feedback">
                    Email inválido. Por favor tente novamente.
                  </div>)}
          </div>
          <div className="contact-form-button-wrapper">
            <button
              disabled={isInvalid}
              type="submit"
              className="button-primary"
            >
              {this.props.locale === 'en' ? 'Send' : 'Enviar'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;

//{error && <p className="typo-captions-red">{error.message}</p>}
