import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import '../styles/pages/contacts.scss';
import '../styles/utils/general.scss';
import ContactForm from '../components/Contacts/ContactForm';
import Img from 'gatsby-image';
import {isSmallScreen} from '../utils/helpers';

class ContactsPage extends React.Component {
  state = {
    logoColor: 'white',
  };

  render () {
    const bgImage = this.props.data.allFile.edges[0].node;

    return (
      <Layout
        path="/contacts"
        locale={this.props.pageContext}
        headerLogoColor={this.state.logoColor}
      >
        {!isSmallScreen ()
          ? <div className="contacts-container">
              <div className="bg-image-contacts">
                <Img fluid={bgImage.childImageSharp.fluid} />
              </div>
              <div className="contacts-background-purple-filter" />
              <div className="contacts-container-body">
                <div className="content-column">
                  <div className="content-column-content-wrapper">
                    <div className="content-column-title">
                      <h2>
                        {this.props.pageContext.locale === 'en'
                          ? 'Do you need help with your project ?'
                          : 'Precisa de ajuda para o seu projeto ?'}
                      </h2>
                    </div>
                    <div className="content-column-email">
                      <h4>info@3decide.com</h4>
                    </div>

                    <div className="content-column-address-wrapper">
                      <div className="content-column-map">
                        <iframe
                          title="google-map"
                          src="https://snazzymaps.com/embed/238409"
                          width="100%" //270
                          height="100%" //252
                          frameBorder="0"
                          allow="fullscreen"
                        />
                      </div>
                      <div className="content-column-address typo-body2-white">
                        <div className="content-column-address-text">
                          Rua da Constituição
                          <br />
                          Founders2Founders
                          <br /> 4200-192 Porto
                          <br />
                          <br />
                          <a
                            href="http://www.3decide.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            www.3decide.com
                          </a>
                          <br />
                          <br />
                          +351 223 205 834

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contacts-form-column">
                  <div className="contacts-form-column-form-wrapper">
                    <ContactForm locale={this.props.pageContext.locale} />
                  </div>
                </div>
              </div>
            </div>
          : <div className="contacts-container">
              <div className="contacts-container-body">
                <div className="content-column">
                  <div className="content-column-content-wrapper">
                    <div className="content-column-title">
                      <h2>
                        {this.props.pageContext.locale === 'en'
                          ? 'Do you need help with your project ?'
                          : 'Precisa de ajuda para o seu projeto ?'}
                      </h2>
                    </div>
                    <div className="content-column-email">
                      <h4>info@3decide.com</h4>
                    </div>

                    <div className="content-column-address-wrapper">
                      <div className="content-column-map">
                        <iframe
                          title="google-map"
                          src="https://snazzymaps.com/embed/238409"
                          width="100%" //270
                          height="100%" //252
                          frameBorder="0"
                          allow="fullscreen"
                        />
                      </div>
                      <div className="content-column-address typo-body2-white">
                        <div className="content-column-address-text">

                          Rua da Constituição
                          <br />
                          Founders2Founders
                          <br /> 4200-192 Porto
                          <br />
                          <br />
                          <a
                            href="http://www.3decide.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: '#ff6b6b'}}
                          >
                            www.3decide.com
                          </a>
                          <br />
                          <br />
                          +351 223 205 834

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contacts-form-column">
                  <div className="bg-image-contacts">
                    <Img fluid={bgImage.childImageSharp.fluid} />
                  </div>
                  <div className="contacts-background-purple-filter" />
                  <div className="contacts-form-column-form-wrapper">
                    <ContactForm locale={this.props.pageContext.locale} />
                  </div>
                </div>
              </div>
            </div>}
      </Layout>
    );
  }
}

export const query = graphql`
  query ContactsPageQuery {
    allFile(filter: { relativeDirectory: { eq: "images/pages/contacts" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default ContactsPage;
